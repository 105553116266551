import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi!`}</p>
    <p>I'm Frederik, a {new Date().getFullYear() - 1990} years old software developer, currently living in Berlin.
Most of the time I am focused on <b>backend development</b> and always eager to learn new technologies.
Currently I'm very interested in topics like <b>clean code</b>, <b>reactive programming</b> and <b>event-driven architectures</b>.
The purpose of this site is to share my experiences whenever I have time to be innovative.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      